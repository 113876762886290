/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~quill/dist/quill.snow.css";

@font-face {
  font-family: 'inter';
  src: url('./assets/fonts/Inter-Regular.ttf');
}

/* FIX PAR CHROME POPOVER SELECT */

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}


:root {
  --ion-font-family: 'inter';
}

// fix issue to click ion-item when it have view responsive
.menu-content-open {
  pointer-events: unset;
}

ion-modal {
  --border-radius: 15px;
}

h1 {
  font-size: 5rem;
  font-weight: bold;
}

h2 {
  font-size: 2.5rem;
  font-weight: bold;
}

h3 {
  font-size: 2rem;
  font-weight: bold;
}

h4 {
  font-size: 1.5rem;
  font-weight: bold;
}

h5 {
  font-size: 1rem;
  font-weight: bold !important;
}

.split-pane-visible > .split-pane-side {
  min-width: 350px !important;
  max-width: 350px !important;
}

.flex {
  display: flex !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.align-center {
  align-items: center !important;
}

.gap-20 {
  gap: 20px !important;
}

.h-full {
  height: 100% !important;
}

.justify-end {
  display: flex !important;
  //justify-content: end !important;
  justify-content: flex-end !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.pointer {
  cursor: pointer !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.sidebar {
  max-width: 400px;

  .sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.w-full {
  width: 100% !important;
}

.text-black {
  color: black !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

.text-error {
  font-size: 12px;
}

.bold {
  font-weight: bold !important;
}

.panel-content-wrapper {
  padding: 1rem;
}

ion-button {
  font-weight: bold !important;
  text-transform: initial !important;
  --box-shadow: none;
  --border-radius: 0.4rem;
  --padding-top: 1.3rem;
  --padding-bottom: 1.3rem;
  --padding-start: 3rem;
  --padding-end: 3rem;

  /*&[size="small"] {
  }*/
}

.ion-custom-small-button {
  --border-color: var(--ion-color-warning);
  --background: white !important;
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: none;
  --padding-top: initial !important;
  --padding-bottom: initial !important;
  --padding-start: 1rem !important;
  --padding-end: 1rem !important;
}

.ion-custom-highlight-small-button {
  --border-color: var(--ion-color-primary);
  --background: var(--ion-color-warning);
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: none;
  --padding-top: initial !important;
  --padding-bottom: initial !important;
  --padding-start: 1rem !important;
  --padding-end: 1rem !important;
}

.ion-custom-modal-button {
  --color: var(--ion-color-medium);
  --border-color: var(--ion-color-light-shade);
  --background: var(--ion-color-light-shade);
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: none;
  --padding-top: initial !important;
  --padding-bottom: initial !important;
  --padding-start: 1rem !important;
  --padding-end: 1rem !important;
}

.ion-custom-button {
  --border-color: var(--ion-color-warning);
  --background: white !important;
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: none;
  --border-radius: 0.4rem;
  --padding-top: 1.3rem;
  --padding-bottom: 1.3rem;
  --padding-start: 3rem;
  --padding-end: 3rem;
}

.custom-input {
  --border-color: rgba(0, 0, 0, 0.13);
  --border-radius: 5px;
  --border-width: 1px;
  --background: #F3F3F3;
  width: 100%;

  ion-icon {
    margin-right: 1rem;
  }
}

ion-input {
  caret-color: black;
}

ion-textarea {
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  color: var(--ion-color-primary-contrast);
  --background: #F3F3F3 !important;
}

ion-select {
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  color: var(--ion-color-primary-contrast);
  background-color: #F3F3F3;
}

.custom-popover {
  --width: 350px !important;
  position: absolute !important;
  left: -50px !important;
}

.dark {
  color: black !important;
  --color: black !important;
}

ion-calendar {
  color: black !important;
  --color: black !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-10 {
  font-size: 10px !important;
}

.fan-loading {
  --background: var(--ion-color-dark-tint);
  color: white;
}

.upload-dropzone-section {
  width: 100% !important;
  height: 160px !important;

  background-color: #F3F3F3;
  background-image: repeating-linear-gradient(0deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px), repeating-linear-gradient(90deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px), repeating-linear-gradient(180deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px), repeating-linear-gradient(270deg, #d2cbcb, #d2cbcb 17px, transparent 17px, transparent 29px, #d2cbcb 29px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.underline {
  text-decoration: underline;
}

.small-modal {
  --width: 30%;
}

.upload-modal {
  --width: 850px;
}

.detailed-modal {
  --width: 975px;
}

.normal-modal {
  --width: 60%;
}

.wide-modal {
  --width: 85%;
}

.fixed-height-small-modal {
  --height: 400px;
}

.custom-auction-progress-modal {
  --width: 975px;
  --height: 737px;
}

.select-products-modal {
  --height: 64%
}

.full-screen-mobile-modal {
  @media screen and (max-width: 768px) {
    --width: 100% !important;
    --height: 100% !important;
  }
}

.w-100 {
  width: 100%;
}

.h-80 {
  --height: 80%;
}

.align-column-content {
  display: flex;
  justify-content: center;
}

// Scrollbar style
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(153, 148, 148);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

ion-item {
  --highlight-color-invalid: rgba(0, 0, 0, 0.13);
}

ion-text[color='danger'] {
  color: #ED6A5E !important;
  font-weight: bold;
  padding-left: 0.25rem;
}

ion-popover {
  --backdrop-opacity: 0;
  --color: black;
  --width: 230px;
}

.alert-button {
  color: var(--ion-color-dark) !important;
  font-weight: bold !important;
}

.body-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.material-symbols-outlined {
  font-size: 1rem !important;
  padding-left: 0.25rem;
}

.ion-color-rose {
  --ion-color-base: var(--ion-color-rose);
  --ion-color-base-rgb: var(--ion-color-rose-rgb);
  --ion-color-contrast: var(--ion-color-rose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
  --ion-color-shade: var(--ion-color-rose-shade);
  --ion-color-tint: var(--ion-color-rose-tint);
}

ion-datetime {
  --ion-color-primary: var(--ion-color-warning);

  border-radius: 16px;
}

.text-black {
  color: black !important;
}

ion-select {
  border: none;
  border-radius: 0;
  color: auto;
  background-color: auto;

  &::part(icon) {
    display: none;
  }
 
}

